/* Variables */
:root {
  --fg3: 0, 0, 0;
  --bg3: 245, 245, 245;
  --brand: #f00;
  --fg: rgba(var(--fg3), 1);
  --bg: rgba(var(--bg3), 1);
  --bgNear: rgba(255, 255, 255, 1);
  --borderColor: rgba(var(--fg3), 0.2);
  --border: 1px solid var(--borderColor);
  --shadow: rgba(0, 0, 0, 0.2);
}
@media (prefers-color-scheme: dark) {
  :root {
    --fg3: 255, 255, 255;
    --bg3: 0, 0, 0;
    --fg: rgba(var(--fg3), 1);
    --bg: rgba(var(--bg3), 1);
    --bgNear: rgba(32, 32, 32, 1);
    --borderColor: rgba(var(--fg3), 0.2);
    --border: 1px solid var(--borderColor);
    --shadow: rgba(255, 255, 255, 0.1);
  }
  .subnav {
    outline: 1px solid #333;
  }
}

html,
body {
  background: var(--bg);
  color: var(--fg);
}

/* Bootstrap overrides */

.checkbox {
  display: inline-block;
  margin: 0;
  user-select: none;
  font-size: 14px;
}
.checkbox:hover {
  background: rgba(var(--fg3), 0.2);
}
.checkbox [type='checkbox'] {
  float: left;
  margin-right: 5px;
  font-size: 1em;
}
.form-control {
  color: var(--fg);
}

/* App */

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: var(--bg);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Subnav */
.subnav {
  position: sticky;
  top: 0;
  flex-wrap: wrap;
  z-index: 3;
  display: flex;
  height: 40px;
  align-items: center;
  align-items: stretch;
  background: var(--bgNear);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
.subnav__spacer {
  flex: 1;
}
.subnav--block {
  flex-wrap: wrap;
  height: auto;
}
.subnav__item {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0 10px;
}
.subnav__item > .form-control {
  background: none;
}
label.subnav__item:hover {
  background: rgba(var(--fg3), 0.2);
}
.subnav a {
  padding: 0 0.5em;
  line-height: 40px;
}
.subnav a:first-child {
  padding-left: 1em;
}
.subnav input,
.subnav button {
  margin-bottom: 0;
}

/* Buttons */
.btn--accept {
  margin-right: -0.5em;
  background: rgba(0, 220, 0, 0.6) !important;
}

/* Review */
.review {
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(var(--fg3), 0.1);
}
.review__row {
  display: flex;
}

/* Photo */
.photo {
  position: relative;
  width: 200px;
  height: 150px;
  cursor: pointer;
  background: #ccc;
  margin-right: 1em;
  margin-bottom: 1em;
  margin: 0 0.5em 1em;
  transition: box-shadow 0.3s;
  box-shadow: 0 0 0 red;
}
.photo img {
  width: 200px;
  height: 150px;
}
.photo--meta {
  width: 320px;
}
.photo--review {
  float: left;
  flex-shrink: 0;
  background: var(--bgNear);
  box-shadow: 0 0 4px var(--shadow);
  cursor: inherit;
}
.photo--lazy {
  background: none;
}
.photo--band {
  opacity: 0.3;
}
.photo--rejected::after {
  content: '?';
  position: absolute;
  display: block;
  top: -4px;
  right: -4px;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  padding-left: 1px;
  line-height: 20px;
  background: red;
  color: white;
  text-align: center;
  box-sizing: border-box;
}
.photo--accepted::after {
  content: 'OK';
  position: absolute;
  display: block;
  top: -4px;
  right: -4px;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  padding-left: 1px;
  line-height: 20px;
  font-size: 11px;
  background: #090;
  color: white;
  text-align: center;
  box-sizing: border-box;
}
.photo--problem {
  /*  content: '';
  position:absolute;
  display: block;
  top: -4px;
  right: -4px;
  background: red;
  width: 20px;
  height: 20px;
  border-radius: 20px;*/
  box-shadow: 0 0 0 3px red;
}
.tagger {
  position: absolute;
  top: -25px;
  top: 0;
  left: 0;
  display: none;
  background: var(--bgNear);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  user-select: none;
}
.photo:hover .tagger {
  display: block;
}
.tagger .check {
  display: block;
  padding: 0 1em 0 5px;
  margin: 0;
  font-size: 14px;
  white-space: nowrap;
}
.tagger .check:hover {
  background: blue;
  color: white;
}
.tagger .checked {
  background: var(--bg);
}
.tagger input {
  margin-right: 5px;
}

.badge + .badge {
  margin-left: 0.5em;
}
.meta {
  position: absolute;
  left: 200px;
  top: 0;
  width: 200px;
}
.box {
  position: absolute;
  outline: 1px solid red;
}
.box::after {
  position: absolute;
  bottom: 100%;
  left: -1px;
  white-space: nowrap;
  content: attr(label);
  color: white;
  text-shadow: 0 0 2px black;
  font-size: 12px;
  line-height: 16px;
}

.gallery {
  display: flex;
  flex-wrap: wrap;
  padding: 1em 0.5em;
}

.good {
  /*border: 2px solid green;*/
}

.bad {
  /*border: 2px solid red;*/
}

.svg {
  position: absolute;
  top: 6px;
  right: 6px;
  display: block;
}

.prev-next {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.prev-next button {
  border: 0 solid black;
  border-radius: 0;
  padding: 0.5em 1em;
  text-transform: uppercase;
  font: inherit;
  font-weight: bold;
  background: none;
  color: inherit;
  cursor: pointer;
}

.prev-next button:focus {
  outline: none;
  background: rgba(var(--fg3), 0.1);
}
.title {
  padding: 0;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  min-width: 120px;
  line-height: 1.1em;
}
.filters {
}
h4 {
  display: inline-block;
  margin: 0;
  margin-right: 1em;
  line-height: 2em;
  font-style: 1em;
  display: none;
}
.status {
}
.rejected,
.status--factory {
  color: red;
  font-weight: bold;
}
.status--slices {
  color: red;
  font-weight: bold;
}

/* Plank*/
.plank__texts {
  display: flex;
  padding-top: 1em;
}
.plank__text {
  padding: 0 1em;
}

/* Home */
.home__cta {
  margin: 2em;
  border-radius: 1em;
  padding: 1em 1.5em;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  text-decoration: none;
  color: var(--bg);
  background: #456;
  text-transform: uppercase;
}

/* Info */
.info__content {
  margin-bottom: 1em;
  padding: 1em;
}
.infobar {
  margin-bottom: 1em;
  padding: 1em;
}
.infobar button {
  margin-right: 20px;
}

table {
  font-family: sans-serif;
  border-collapse: collapse;
}
table {
  width: 30em;
}

tr > td:first-child {
  font-weight: bold;
}

tr > td {
  padding-bottom: 1em;
}

th {
  border-bottom: 1px solid black;
}

.number {
  text-align: right;
}

.toggleInfo {
  position: fixed;
  right: 1em;
  top: 1em;
}
